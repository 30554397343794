@font-face {
	font-family: "xkey-icons";
	src: url('../fonts/xkey-icons.eot');
	src: url('../fonts/xkey-icons.eot?#iefix') format('eot'),
		url('../fonts/xkey-icons.woff2') format('woff2'),
		url('../fonts/xkey-icons.woff') format('woff'),
		url('../fonts/xkey-icons.ttf') format('truetype'),
		url('../fonts/xkey-icons.svg#xkey-icons') format('svg');
}

@mixin xkey-icon-styles {
	font-family: "xkey-icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
}

%xkey-icon {
	@include xkey-icon-styles;
}

@function xkey-icon-char($filename) {
	$char: "";

	@if $filename == arrows_circle_check {
		$char: "\E001";
	}
	@if $filename == arrows_circle_down {
		$char: "\E002";
	}
	@if $filename == arrows_circle_filled_down {
		$char: "\E003";
	}
	@if $filename == arrows_circle_left {
		$char: "\E004";
	}
	@if $filename == arrows_circle_minus {
		$char: "\E005";
	}
	@if $filename == arrows_circle_plus {
		$char: "\E006";
	}
	@if $filename == arrows_circle_remove {
		$char: "\E007";
	}
	@if $filename == arrows_circle_right {
		$char: "\E008";
	}
	@if $filename == arrows_circle_up {
		$char: "\E009";
	}
	@if $filename == arrows_left {
		$char: "\E00A";
	}
	@if $filename == arrows_right {
		$char: "\E00B";
	}
	@if $filename == cart {
		$char: "\E00C";
	}
	@if $filename == circle_cart {
		$char: "\E00D";
	}
	@if $filename == linkedin {
		$char: "\E00E";
	}
	@if $filename == paper-plane {
		$char: "\E00F";
	}
	@if $filename == play-button {
		$char: "\E010";
	}
	@if $filename == play {
		$char: "\E011";
	}
	@if $filename == plugin {
		$char: "\E012";
	}
	@if $filename == plugins {
		$char: "\E013";
	}
	@if $filename == xing {
		$char: "\E014";
	}
	@if $filename == youtube {
		$char: "\E015";
	}

	@return $char;
}

@mixin xkey-icon($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %xkey-icon;
		} @else {
			@include xkey-icon-styles;
		}
		content: xkey-icon-char($filename);
	}
}

.xkey-icon-arrows_circle_check {
	@include xkey-icon(arrows_circle_check);
}
.xkey-icon-arrows_circle_down {
	@include xkey-icon(arrows_circle_down);
}
.xkey-icon-arrows_circle_filled_down {
	@include xkey-icon(arrows_circle_filled_down);
}
.xkey-icon-arrows_circle_left {
	@include xkey-icon(arrows_circle_left);
}
.xkey-icon-arrows_circle_minus {
	@include xkey-icon(arrows_circle_minus);
}
.xkey-icon-arrows_circle_plus {
	@include xkey-icon(arrows_circle_plus);
}
.xkey-icon-arrows_circle_remove {
	@include xkey-icon(arrows_circle_remove);
}
.xkey-icon-arrows_circle_right {
	@include xkey-icon(arrows_circle_right);
}
.xkey-icon-arrows_circle_up {
	@include xkey-icon(arrows_circle_up);
}
.xkey-icon-arrows_left {
	@include xkey-icon(arrows_left);
}
.xkey-icon-arrows_right {
	@include xkey-icon(arrows_right);
}
.xkey-icon-cart {
	@include xkey-icon(cart);
}
.xkey-icon-circle_cart {
	@include xkey-icon(circle_cart);
}
.xkey-icon-linkedin {
	@include xkey-icon(linkedin);
}
.xkey-icon-paper-plane {
	@include xkey-icon(paper-plane);
}
.xkey-icon-play-button {
	@include xkey-icon(play-button);
}
.xkey-icon-play {
	@include xkey-icon(play);
}
.xkey-icon-plugin {
	@include xkey-icon(plugin);
}
.xkey-icon-plugins {
	@include xkey-icon(plugins);
}
.xkey-icon-xing {
	@include xkey-icon(xing);
}
.xkey-icon-youtube {
	@include xkey-icon(youtube);
}