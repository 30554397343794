


.navbar {
    @include transition(all 0.3s ease);

    .container {
        @include media-breakpoint-down(sm) {
            padding:0px;
            margin:0px;
            width:100%;
        }
    }

    .nav-item {
        text-transform: uppercase;
    }

    @include media-breakpoint-up(lg) {
        .navbar-nav {
          .nav-link {
            padding: .8em .9em;
          }
        }
    }

    .logo {
        @include transition(all 0.3s ease);
        max-height: 50px;
    }

    .navbar-toggler {
        border: none;
        margin-top: 5px;
        margin-right: -5px;
    }

    &:focus, button:focus {
        border-color: transparent;
        box-shadow: none;
        outline: 0 none;
    }
    
    .dropdown-menu {
        border-radius: 0px !important;
    }

    a.target-burger {
        margin: 0;
        width: 30px;
        height: 30px;
        //position: absolute;
        display: block;
        @include transition(0.5s);
        padding: 10px;

        span {
            float: left;
            margin: ($height-icon - 3) auto $height-icon auto;
            color: $menu-icon-dark-color;
            font-size: 20px;
        }

        &:hover {
            cursor: pointer;
            @include opacity(0.45);
        }

        ul.buns {
            float:left;
            color: $menu-icon-dark-color;
            width:$width-line;
            height:$height-icon;
            position:relative;
            display:block;
            //margin: ($height-icon * 2) auto $height-icon auto;
            //margin-top:20px;

            li.bun {
                display:block;
                background:$menu-icon-dark-color;
                width:$width-line;
                height:$height-line;
                position:absolute;
                left:0;
                border-radius:($height-line / 2);
                transition: all $transition-time;
                -webkit-transition: all $transition-time;
                -moz-transition: all $transition-time;

                &.line-1 {
                  top:0;
                }
                &.line-2 {
                  top:50%;
                }
                &.line-3 {
                  top:100%;
                }
            }


        }

        &.toggled {
            @include opacity(1);

            ul.buns{
                .line-1 {
                  transform: translateY($translateY) translateX($translateX) rotate($rotation);
                  -webkit-transform: translateY($translateY) translateX($translateX) rotate($rotation);
                  -moz-transform: translateY($translateY) translateX($translateX) rotate($rotation);
                    //background: $white;
                }
                .line-2 {
                  opacity:0;
                }
                .line-3 {
                  transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
                  -webkit-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
                  -moz-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
                    //background: $white;
                }
            }
        }
    }

    &.navbar-light, &.navbar-inverse {
        a.target-burger {
            span {
                color: $menu-icon-light-color;
            }

            ul.buns {
                color: $menu-icon-light-color;

                li.bun {
                    background: $menu-icon-light-color;
                }
            }
        }
    }

    /*@include media-breakpoint-down(sm) {

        .nav-item {
            text-align: center;
            font-size: 1.5rem;
        }


        .navbar-collapse {
            &.show {

            }
        }

        .nav-primary {
            @include transition(0.5s);
            -webkit-animation: slideIn 0.5s forwards;
            -moz-animation: slideIn 0.5s forwards;
            animation: slideIn 0.5s forwards;

            height: 100vh;
            margin-right: 20px;
            padding-top: 30px;
            //@include opacity(0.8);
        }

        &.navbar-light {
            @include transition(all 0.3s ease);
            background: $brand-primary;
        }

        .navbar-inverse {
            #xkey-navbar {
                background: #000 !important;
            }
        }

        .navbar-brand {
            padding-left: 10px;
        }
    }*/

    @include media-breakpoint-down(sm) {
        
        .nav-link{
            border: none !important;
        }
        .menu-item-has-children {
            & > a {
                display: none;
            }
        }
        .dropdown-menu {
            display: block !important;
            background: transparent;
            border: none !important;
            text-align: center;
            
            a {
                font-size: 1.5rem;
            }
        }

        a.target-burger {
            &.toggled {
                ul.buns{
                    .line-1 {
                        background: $white !important;
                        z-index: 999;
                    }
                    .line-2 {
                        background: $white !important;
                        z-index: 999;
                    }
                    .line-3 {
                        background: $white !important;
                        z-index: 999;
                    }
                }
            }
        }

        .navbar-collapse {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            height: 100vh;
            background: $brand-primary;
        }

        .nav-item {
            text-align: center;
            font-size: 1.5rem;


            a {
                color: rgba($white,0.5) !important;
            }

            &:hover, &.current-menu-item {
                a {
                    color: $white !important;
                }
            }

        }


        .navbar-collapse {
            @include transition(all 0.5s ease);
            @include transform(translateY(-100vh));

            &.show {
                @include transform(translateY(0px));
            }
        }

        .navbar-brand {
            .logo {
                padding-top: 10px;
                padding-left: 10px;
            }
        }

        .nav-primary {

            //-webkit-animation: slideIn 0.5s forwards;
            //-moz-animation: slideIn 0.5s forwards;
            //animation: slideIn 0.5s forwards;


            height: 100vh;
            margin-right: 20px;
            padding-top: 30px;
            //@include opacity(0.8);
        }

        &.navbar-light {
            @include transition(all 0.3s ease);
            background: $brand-primary;
        }

        .meta-nav {
            background: $white;
            z-index: 900;
            padding-top: 9px;
            li, a {
                padding: 5px;
                font-size: 0.8rem;
            }

            a {
                color: $black !important;

                &:hover {
                    color: $black !important;
                }
            }

            .menu-item-1390 {
                display: none !important;
            }
        }

        .cart-contents {
            display: none !important;
        }
    }
}

@include media-breakpoint-down(sm) {
    #product-navigation {
        display: none !important;
    }
    
    #meta-nav {
        display: none;
    }

}




    #main-navigation {
        z-index: 99;
        //top: 50px;
        box-shadow: 1px 1px 16px #e1e1e1;
        padding: 0px;

        .nav-item {
            .nav-link {
                border-bottom: 1px solid transparent;
            }

            &:hover, &.active {
                .nav-link {
                    border-bottom: 1px solid $black;
                }
            }
        }


        &.bg-faded {
            background: transparent !important;
            box-shadow: none !important;

            .nav-item {
                &:hover, &.active {
                    .nav-link {
                        border-bottom: 1px solid transparent;
                    }
                }
            }
        }

        &.navbar-light {
            .cart {
                border-color: transparent !important;

                a {
                    border-color: transparent !important;
                    .badge {
                        color: rgba(0,0,0,.5);
                        background: transparent;
                        border-color: transparent;
                    }

                    &:hover {
                        .badge {
                            color: rgba(0,0,0,.5);
                            background: transparent;
                            border-color: transparent;
                        }
                    }
                }
            }
        }
    }

    #meta-nav {
        z-index: 98;
        .nav-link {
            padding-top: 2px;
            padding-bottom: 2px;
            padding-right: 0px;
            padding-left: 0px;
            font-size: 0.8rem;

            &:before {
                content: ' | ';
                padding-left: 5px;
                padding-right: 5px;
            }
        }

        .nav-item {
            &:hover, &.active {
                .nav-link {
                    &:before {
                        color: rgba(255, 255, 255, 0.5) !important;
                    }
                }
            }
        }

        .nav-item:first-of-type {
            .nav-link {
                &:before {
                    content: '';
                }
            }
        }


    }


.page-header {
    /*background-color: $brand-primary;
    text-align: center;
    box-shadow: 1px -3px 10px 1px $brand-shadow;
    padding: 20px;
    @include media-breakpoint-up(md) {
        padding: 50px;
    }

    h1 {
        margin-top: $nav-item-margin;
        text-align: center;
        text-transform: none;

        @include media-breakpoint-down(sm) {
            margin-top: 50px;
        }

        @include media-breakpoint-up(md) {
            padding: 30px;
        }
    }
    */

}

.product-navigation {
  .nav {

  }
}

.sticky.is-sticky {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
}
