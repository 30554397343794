.product-listing {
  @include brand-hr($black);
  @include widget-titles;
  @include content-element;

  background: $brand-gray;

  article {
    .card {
      border: 1px solid rgba(0,0,0,.125);
      //background: $white;
      margin-bottom: 15px;
    }

    .card-block {
      background: $white;
    }

    h4 {
      color: $brand-primary;
      font-size: 1.5rem;
    }
  }
}

.plugin-listing {
  @include brand-hr($black);
  @include widget-titles;
  @include content-element;

  background: $brand-gray;

  article {
    .card {
      border: 1px solid rgba(0,0,0,.125);
      //background: $white;
      margin-bottom: 15px;
    }

    .card-block {
      background: $white;
    }

    h4 {
      color: $brand-primary;
      font-size: 1.5rem;
    }
  }
}

.related-blogposts {
  @include brand-hr($white);
  @include widget-titles;
  @include content-element;

  background: var(--main-color, $brand-primary);

  h2, h3 {
    color: $white;
  }

  hr {
    border-color: $white;
    background: $white;
  }

  article {
    border: 1px solid rgba(0,0,0,.125);
    padding: 15px;
    min-height: 346px;
    background: $white;

    h4 {
      font-size: 1.1rem;
      line-height: 1.5rem;
    }
    p {
      /*height: 190px;
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;*/
      word-break: break-word;
    }

    span {
        text-transform: uppercase;

        &.date {

        }

        a {

        }
    }
  }
}

.columns-rows {
    @include brand-hr($black);
    @include widget-titles;
    @include content-element;

    hr {
        margin-bottom: 15px;
    }

    .row {
        margin-top: 30px;
    }

    section {
        .column {
            .icon {
                color: $brand-primary;
                font-size: 2rem;

                padding: 15px;
                padding-left: 0px;
                padding-top: 0px;
                margin-top: -10px;

                i {
                    line-height: 0;
                }
            }

            .text {

            }
        }
    }
}

.accordion {
    @include brand-hr($black);
    @include widget-titles;
    @include content-element;

    background: $brand-gray;

    .infograph {
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: contain;
        min-height: 320px;
        max-height: 320px;
    }

    hr {
        margin-bottom: 15px;
    }

    @include media-breakpoint-up(md) {
        .header {
            margin-left: -30px;
        }
    }



    .icon {
        font-size: 3rem;
        color: $brand-primary;
        z-index: 99;


    }

    a {
        text-decoration: none !important;

        &:active, &:focus {
            text-decoration: none !important;

            i {
                color: $brand-primary;
            }
        }

        &:hover {
            text-decoration: none !important;

            i {
                color: $black;
            }

            .card-header {
                background: $gray-lighter;
            }
        }
    }

    .card-header {
        border-radius: 30px;
        border: 1px solid $gray-lighter;
        width: 70%;
        margin-left: -40px;
        padding:9px;
        padding-left: 50px;
        z-index: 80;
        margin-top: -5px;

        h5 {
            margin: 0px;
            color: $black;
        }

        a {
            text-decoration: none !important;
        }
    }

    .collapse-content {
        padding-left: 10px;
        padding-top: 0px;

        .card-block {
            padding-top: 0px;
        }

        .row-item {
            .title {
                font-size: calc(#{$font-size-base} + #{0.1rem});
                font-size: 600;
            }
        }
    }




}

.pricing-table {
    @include brand-hr($black);
    @include widget-titles;
    @include content-element;

    background: $brand-gray;

    section {

        text-align: center;

        .column {
            background: $white;
            text-align: center;
            border: 1px solid $gray-lighter;

            header {
                width: 100%;

                h4 {
                    font-weight: 300;
                    padding: 5px;
                    padding-top: 15px;
                    padding-bottom: 15px;
                }

                .price {
                    display: block;
                    font-size: $font-size-xl;
                    color: $brand-primary;
                    padding: 5px;
                    background: $brand-gray1;
                }
            }

            .list-content {
                min-height: 130px;

                ul {
                    list-style: none;
                    padding: 5px;
                    margin: 0px;
                    li {
                        text-align: center;
                        padding: 3px;
                        list-style: none;
                        //font-size: $font-size-sm;
                    }
                }
            }

            footer {
                padding: 5px;
                padding-bottom: 15px;
                .btn {
                    text-transform: uppercase;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
            }


        }
    }

    @include media-breakpoint-down(sm) {
        .column {
            margin-bottom: 30px;
        }
    }
}

.download-table {
    @include brand-hr($black);
    @include widget-titles;
    @include content-element;

    background: $brand-gray2;
    section {
        text-align: center;
        .column {
            background: $white;
            text-align: center;
            border: 1px solid $gray-lighter;
            height: 100%;

            h4 {
                font-weight: 400;
                padding: 5px;
                padding-top: 15px;
                margin:0px;
            }

            .btn {
                text-transform: uppercase;
                margin: 5px;
                padding-top: 5px;
                padding-bottom: 5px;
            }

            .version {
                display: block;
                font-size: $font-size-xl;
                color: $brand-primary;
                padding: 5px;
                font-weight: 300;
                padding-top: 5px;
            }

            .text {
                padding: 5px;
                padding-bottom: 15px;

                p {
                    margin: 0px;
                    padding: 0px;
                }
                //font-size: $font-size-sm;
            }
        }
    }

    .instructions {
        font-size: $font-size-sm;
        padding: 20px;
        padding-bottom: 0px;

        p {
            margin: 0px;
            padding: 0px;
        }
    }

    @include media-breakpoint-down(sm) {
        .column {
            margin-bottom: 30px;
        }
    }
}

.contact-form {
    @include brand-hr($white);
    @include widget-titles;
    @include content-element;

    background: $gray;

    h2, h3 {
        color: $white;
    }

    .wpcf7-recaptcha {
        display: inline-block;
        text-align: center;
    }

    .btn {
        cursor: pointer;
        background: $black;
        color: $white;
        border-radius: 50%;
        border-color: $black;

        &:hover {
            background: $brand-primary;
            color: $white;
            border-color: $brand-primary;
        }

        i {
            font-size: 2.5rem;
        }
    }

    .row {
        margin-bottom: 10px;
    }

    .wpcf7-validation-errors {
        background: $black;
        color: $white;
        border-color: transparent;
        padding: 10px;
    }
}

.video-action {
    @include widget-titles;

    background-color: $brand-primary;
    background-size: cover;
    background-repeat: no-repeat;

    text-align: center;

    min-height: 250px;

    h2 {
        padding-top: 50px;
        padding-bottom: 30px;
        color: $white;
        font-weight: 300;
        text-align: center;
    }

    a {
        padding: 15px;
        padding-top: 0px;
        text-decoration: none;

        i {
            color: $white;
            font-size: 3.5rem;
        }

        span {
            color: $white;
            display: block;
            /*border-radius: 30px;
            border: 1px solid $white;
            margin-left: -40px;*/
            padding:9px;
            //padding-left: 45px;
            //padding-right: 30px;
            text-transform: uppercase;
            font-size: $font-size-xl;
        }

        &:hover {
            text-decoration: none;

            i {
                color: $black;
            }
            span {
                color: $black;
            }
        }
    }
}

.screenshots {
    @include brand-hr($black);
    @include widget-titles;
    @include content-element;
}
