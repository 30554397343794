// Set a rem font size with pixel fallback
/* USAGE: 
p {
  @include font-size(14px)
}
*/

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

// Animations and keyframes
/* USAGE
@include keyframes(slide-down) {
  0% { opacity: 1; }
  90% { opacity: 0; }
}

.element {
  width: 100px;
  height: 100px;
  background: black;
  @include animation('slide-down 5s 3');
}
*/
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}

// TRANSISTIONS
/* USAGE 
a {
  color: gray;
  @include transition(color .3s ease);
  &:hover {
    color: black;
  }
}
*/
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}

// OPACITY
/* USAGE
.faded-text {
  @include opacity(0.8);
}
*/
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// CLEARFIX
/* USAGE
.container-with-floated-children {
  @extend %clearfix;
}
*/
%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// text with image element
@mixin content-element {
    @include media-breakpoint-up(xs) {
        padding-top: 25px;
        padding-bottom: 25px;
    }
    
    @include media-breakpoint-up(md) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

@mixin content-element-top {
    @include media-breakpoint-up(xs) {
        padding-top: 25px;
    }
    
    @include media-breakpoint-up(md) {
        padding-top: 50px;
    }
}


@mixin brand-hr($color) {
    h2 {
        margin-bottom: 0px;
    }
    
    hr {
        border-color: $color;
        //border: none;
        background: $color;
        //height: .10rem;
        width: 5rem;
        margin-bottom: 30px;
    }
}

@mixin header-hr($color) {
    text-align: center;
    margin-bottom: 15px;

    &:after {
        content: ' ';
        height: .10rem;
        width: 5rem;
        background: $color;
        display: block;
        text-align: center;
        margin: 0 auto;
        margin-top: 15px;
    }
}

@mixin widget-titles() {
    h2 {
        text-align: center;
        font-weight: 300;
        margin-bottom: 5px;
    }
    
    h3 {
        text-align: center;
        font-weight: 300;
        margin-bottom: 0px;
    }
}

@mixin make-grid-columns($columns: $grid-columns, $gutters: $grid-gutter-widths, $breakpoints: $grid-breakpoints) {
  // Common properties for all breakpoints
  %grid-column {
    position: relative;
    width: 100%;
    min-height: 1px; // Prevent columns from collapsing when empty

    @include make-gutters($gutters);
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    // Allow columns to stretch full width below their breakpoints
    @for $i from 1 through $columns {
      .xcol#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }
    .xcol#{$infix} {
      @extend %grid-column;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .xcol#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .xcol#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;
      }

      @for $i from 1 through $columns {
        .xcol#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }

      @each $modifier in (pull, push) {
        @for $i from 0 through $columns {
          .#{$modifier}#{$infix}-#{$i} {
            @include make-col-modifier($modifier, $i, $columns)
          }
        }
      }

      // `$columns - 1` because offsetting by the width of an entire row isn't possible
      @for $i from 0 through ($columns - 1) {
        @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-xs-0
          .xoffset#{$infix}-#{$i} {
            @include make-col-modifier(offset, $i, $columns)
          }
        }
      }
    }
  }
}

@include make-grid-columns(20);