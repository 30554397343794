/*
 * 	Default theme - Owl Carousel CSS File
 */

$color-base:			$brand-primary !default;
$color-white:			#FFF !default;
$color-gray:			$gray !default;

//nav

$nav-color: 			$color-white !default;
$nav-color-hover:		$color-white !default;
$nav-font-size: 		14px !default;
$nav-rounded: 			3px;
$nav-margin: 			5px !default;
$nav-padding: 			4px 7px !default;
$nav-background: 		$color-gray !default;
$nav-background-hover:	$color-base !default;
$nav-disabled-opacity: 	0.5 !default;

//dots

$dot-width:				10px !default;
$dot-height:			10px !default;
$dot-rounded:			30px !default;
$dot-margin: 			5px 7px !default;
$dot-background:		$color-gray !default;
$dot-background-active:	$color-base !default;

@import "../../../bower_components/owl.carousel/src/scss/theme";


.owl-theme {
	.owl-nav {
		[class*='owl-'] {
			border-radius: 0px;
		}
	}
}

/*.owl-wrapper-outer {
  border: 1px solid red;
  font: 0/0 a;
  line-height: 0;
}
*/



.owl-prev {
    position: absolute;
    top: 40%; 

    display: block !important;
    border:0px solid black;
    background: none !important;
    
    left: 0px;
    width: auto;
    height: auto;
    margin-left: 0px;
    margin-right: 0px;
    left: -60px;
}

.owl-next {
    position: absolute;
    top: 40%;
    
    display: block !important;
    border:0px solid black;
    background: none !important;

    width: auto;
    height: auto;
    margin-right: 0px;
    margin-left: 0px;
    right: -60px;
}
.owl-prev i, .owl-next i {font-size: 30px; color: $black;}
.owl-prev:hover i, .owl-next:hover i {color: $brand-primary;}

@include media-breakpoint-down(md) { 
    .owl-nav, .owl-next, .owl-prev {
        display: none !important;
    }
}