.copyright {
    @include media-breakpoint-up(xs) {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    @include media-breakpoint-up(md) {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    
    .row {
        .col-sm-5 {
            border-top: 1px solid $brand-footer-text;
            padding-top: 15px;
        }
    }
    
    background: $brand-copyright-footer;
    color: $brand-footer-text;
    font-size: $font-size-sm;
    
    a {
        color: $brand-footer-text;
        @include opacity(1);
        @include transition(all 0.3s ease);
        i {
            font-size: 1.2rem;
            vertical-align: middle;
            line-height: 0;
        }
        &:hover {
            color: $brand-footer-text;
            text-decoration: none;
            @include opacity(0.8);
        }
    }
}

.content-info {
    background: $brand-footer;
    margin: 0px !important;
    
    .container {
        @include media-breakpoint-up(xs) {
            padding: 20px;
        }

        @include media-breakpoint-up(md) {
            padding: 70px;
            padding-left: 100px;
            padding-right: 100px;
        }
    }

    section {
        h3 {
            font-size: $font-size-base;
            font-weight: 600;
            color: $brand-footer-text;
            text-transform: uppercase;
        }
        
        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;
            
            li {
                color: $brand-footer-text;
                list-style: none;
                font-size: $font-size-base;
            }
        }
        
        a {
            font-weight: 400;
            color: $brand-footer-text;
            font-size: $font-size-base;
        }
        
        a:hover {
            text-decoration: underline;
        }
    }
}