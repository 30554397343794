.page-template-template-products {
  .description {
    padding-top: 30px;
    padding-bottom: 15px;
    h1 {
        font-size: 1.8rem;
        color: var(--main-color, $brand-primary);
    }

    p {
      font-size: 1rem;
    }

    .product-logo {
      width: 80px;
      margin-right: 10px;
      height: 80px;
    }

    .link-btn {
      padding-right: 30px;
      i {
        color: var(--main-color, $brand-primary);
        font-size: 50px;
        @include transition(all 0.3s);
      }

      .price, .version {
        font-style: italic;
        color: $black;
        @include transition(all 0.3s);
      }

      .additional {
        color: $black;
        @include transition(all 0.3s);
      }

      .buynow, .download {
        color: $black;
        text-transform: uppercase;
        font-weight: bold;
        @include transition(all 0.3s);
      }

      .flex-column {
        padding-left: 15px;
        margin-top: -5px;
      }

      a {
        text-decoration: none;
        @include transition(all 0.3s);
        padding-left: 15px;
        padding-right: 15px;

      }

      a:hover {
        background: var(--main-color, $brand-primary);
        color: $white !important;

        i {
          color: $white !important;
        }

        .additional, .buynow, .download, .price, .version {
          color: $white !important;
        }
      }

    }
  }
  .columns-rows {
    background: $brand-gray;
  }

  .wrap {
    padding-bottom: 0px !important;
  }

  .product-logo {
    width: 100px;
  }
}
