.bg-dark {
    background: $brand-dark;
}


.vc_icon_element-icon {
    color: $brand-primary;
}

.white {
    color: $white;
}

@mixin icon($size: 8rem, $padding: 20px, $bg: $brand-primary, $color: $white) {
    .icon {
        i {
            font-size: $size;
            border-top-right-radius: 50%;
            border-top-left-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;
            background: $bg;
            padding: $padding;
            color: $color;
        }
    }
}

@mixin activity {
    section {
        .activity {
            margin-top: 20px;
            margin-bottom: 20px;
            background: $white;
            width: 100%;
            min-height: 188px;
            

            .row {
                padding: 20px;
                min-height: 120px;
                
                h5 {
                    font-size: $font-size-base;
                }
            }
            
            p {
                font-size: $font-size-sm;
            }

            .btn {
                width: 100%;
                text-align: right;
                text-transform: uppercase;
            }
        }  
        
        .activity-header {
            padding-left: 30px; 
        }
        
        @include icon(4rem, 10px);
    }
}

img.desaturate {
  -webkit-filter: grayscale(100%);
  -webkit-filter: grayscale(1);
  filter: grayscale(100%);
 
  filter: url(../images/desaturate.svg#greyscale); 
  filter: gray;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
}

@include media-breakpoint-down(sm) {
    h1 {
        font-size: 1.8rem;
        word-break: break-word;
    }
}

.img-circle {
    border-radius: 50%;
}


/* */
table, div, a {
    //border-radius: 0px !important; 
}

.bg-black {
    background: $black;
}

.wrap {
    padding-top: 25px;
    padding-bottom: 25px;
}

.home, .archive, .page-template-template-home {
    .wrap {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    
    .slider {
        margin-top: -100px;
    }
}

.bold {
    font-weight: bold;
}

.btn-round {
    border-radius: 20px !important;
}