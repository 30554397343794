$output-bourbon-deprecation-warnings: false;
// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$white:  #fff;
$black:  #000;
$red:    #d9534f;
$orange: #f0ad4e;
$yellow: #ffd500; 
$green:  #5cb85c;
$blue:   #0275d8; 
$teal:   #5bc0de;
$pink:   #ff5b77;
$purple: #613d7c;

$brand-primary:             #ef8921;

$brand-black:               #151615;
$brand-gray:                #f9f9f9;
$brand-gray1:                #fbfbfb;
$brand-gray2:                #f2f2f2;
$brand-shadow:              #3a3a3a;
$brand-dark:                #313131;
$brand-footer:              #333333;
$brand-copyright-footer:    #333333;
$brand-contact:             #3a3a3a;
$brand-footer-text:         #7d7d7d;

$edoc: #16b4ce;
$fileconv: #e14e26;

// Create grayscale
$gray-dark:                 #292b2c;
$gray:                      #464a4c;
$gray-light:                #636c72;
$gray-lighter:              #eceeef; 
$gray-lightest:             #f7f7f7; 
$gray-info: #999999;

$hr: #818383;

// Reassign color vars to semantic color scheme

$brand-success:             $green;
$brand-info:                $teal;
$brand-warning:             $orange;
$brand-danger:              $red;
$brand-inverse:             #000; // $gray-dark


// border
$border-radius:          30px;
$border-radius-lg:       30px;
$border-radius-sm:       30px;

$font-family-sans-serif: 'Open Sans', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-serif:      'Open Sans', Georgia, "Times New Roman", Times, serif;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:       $font-family-sans-serif;

$font-size-base: 0.9rem; // Assumes the browser default, typically `16px`
$font-size-xl:   1.5rem;
$font-size-lg:   1.25rem;
$font-size-sm:   .775rem;
$font-size-xs:   .65rem;


// navbar
$nav-item-margin:               .2rem;
$nav-item-inline-spacer:        1rem;
$nav-link-padding:              .5em 1em;

$font-weight-normal: normal;
$font-weight-bold: bold;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$font-size-h1: 2.5rem;
$font-size-h2: 2.1rem;
$font-size-h3: 1.4rem;
$font-size-h4: 1.8rem;
$font-size-h5: 1.1rem;
$font-size-h6: 1rem;

$headings-font-family:   inherit;
$headings-font-weight:   600;
$headings-line-height:   1.1;
$headings-color:         inherit;

$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$display1-weight:     600;
$display2-weight:     800;
$display3-weight:     500;
$display4-weight:     300;
$display-line-height: $headings-line-height;

$body-bg:       $white;
$body-color:    $gray-dark;
$inverse-bg:    $gray-dark;
$inverse-color: $gray-lighter; 

$btn-primary-color:              $white;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             $btn-primary-bg;

$btn-secondary-color:            $white;
$btn-secondary-bg:               transparent;
$btn-secondary-border:           $white;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            .75rem;
$table-sm-cell-padding:         .3rem;

$table-bg:                      $white; 

$table-inverse-bg:              $gray-dark;
$table-inverse-color:           $body-bg;

$table-bg-accent:               $white;
$table-bg-hover:                rgba($black,.075);
$table-bg-active:               $table-bg-hover;

$table-head-bg:                 $gray-lighter;
$table-head-color:              $gray;

$table-border-width:            1px;
$table-border-color:            transparent;

// Cards

$card-spacer-x:            1.25rem !default;
$card-spacer-y:            .75rem !default;
$card-border-width:        0px;
$card-border-radius:       0px;
$card-border-color:        transparent;
$card-border-radius-inner: calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg:              transparent;
$card-bg:                  transparent;

$card-link-hover-color:    $white !default;

$card-img-overlay-padding: 1.25rem !default;

$card-columns-count:        3 !default;
$card-columns-gap:          1.25rem !default;
$card-columns-margin:       $card-spacer-y !default;

$input-border-radius:            0 !default;
$input-border-radius-lg:         0 !default;
$input-border-radius-sm:         0 !default;


$height-icon: 17px;
$width-line: 27px;
$height-line: 2px;

$transition-time: 0.4s;
$rotation: 45deg;
$translateY: ($height-icon / 2);
$translateX: 0;
$menu-icon-light-color: $white;
$menu-icon-dark-color: $black; 

$logo-zindex: 900;
$logo-width: 400px;
$logo-bottom: 70px;
$logo-margin: 20px;